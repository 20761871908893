@use "canopy/styles/mixins";

// CSS VARIABLES

:root {
  // RGB COLOR VARIANTS
  --color-white-rgb: 255, 255, 255;
  --color-black-rgb: 0, 0, 0;
  --color-charcoal-rgb: 51, 51, 51;
  --color-leafy-green-rgb: 2, 136, 68;
  --color-dandelion-yellow-rgb: 255, 213, 0;
  --color-poppy-red-rgb: 226, 41, 34;
  --color-white-smoke-rgb: 246, 246, 246;
  --color-charcoal-dark: #1a1a1a;
  // BACKGROUND OPACITY
  --background-opacity: 0.9;
  --brand-icon-fill-primary: transparent;
  // LINE HEIGHT
  --text-fs-7-line-height: 1.22;
  --text-fs-6-line-height: 1.23;
  --text-fs-1-line-height: 1.75;
}

html {
  scroll-behavior: smooth;
}

.body {
  overflow-x: hidden;
  background-color: var(--color-white);
  height: auto;
  line-height: var(--text-fs-1-line-height);

  &.target-hide {
    opacity: 0 !important;
  }
}

// BACKGROUND SOLID COLORS

.bg-color-white {
  background-color: var(--color-white);
}

.bg-color-blue {
  background-color: var(--color-super-blue);
}

.bg-color-light-blue {
  background-color: var(--color-super-blue-lightest);
}

.bg-color-green {
  background-color: var(--color-leafy-green);
}

.bg-color-light-green {
  background-color: var(--color-leafy-green-lightest);
}

.bg-color-yellow {
  background-color: var(--color-dandelion-yellow);
}

.bg-color-light-yellow {
  background-color: var(--color-dandelion-yellow-lightest);
}

.bg-color-red {
  background-color: var(--color-poppy-red);
}

.bg-color-light-red {
  background-color: var(--color-poppy-red-lightest);
}

.bg-color-charcoal {
  background-color: var(--color-charcoal);
}

.bg-color-lightest-grey {
  background-color: var(--color-white-smoke);
}

.bg-color-light-grey {
  background-color: var(--color-platinum);
}

.bg-color-transparent {
  background-color: transparent;
}

// BACKGROUND SEE TROUGH COLORS

.bg-color-white--transparent {
  background-color: rgba(var(--color-white-rgb), var(--background-opacity));
}

.bg-color-blue--transparent {
  background-color: rgba(
    var(--color-super-blue-rgb),
    var(--background-opacity)
  );
}

.bg-color-lightest-blue--transparent {
  background-color: rgba(
    var(--color-super-blue-lightest-rgb),
    var(--background-opacity)
  );
}

.bg-color-green--transparent {
  background-color: rgba(
    var(--color-leafy-green-rgb),
    var(--background-opacity)
  );
}

.bg-color-lightest-green--transparent {
  background-color: rgba(
    var(--color-leafy-green-lightest-rgb),
    var(--background-opacity)
  );
}

.bg-color-yellow--transparent {
  background-color: rgba(
    var(--color-dandelion-yellow-rgb),
    var(--background-opacity)
  );
}

.bg-color-lightest-yellow--transparent {
  background-color: rgba(
    var(--color-dandelion-yellow-lightest-rgb),
    var(--background-opacity)
  );
}

.bg-color-red--transparent {
  background-color: rgba(var(--color-poppy-red-rgb), var(--background-opacity));
}

.bg-color-lightest-red--transparent {
  background-color: rgba(
    var(--color-poppy-red-lightest-rgb),
    var(--background-opacity)
  );
}

.bg-color-charcoal--transparent {
  background-color: rgba(var(--color-charcoal-rgb), var(--background-opacity));
}

.bg-color-lightest-grey--transparent {
  background-color: rgba(
    var(--color-white-smoke-rgb),
    var(--background-opacity)
  );
}

.bg-color-light-grey--transparent {
  background-color: rgba(var(--color-platinum-rgb), var(--background-opacity));
}

// LINK ON COLOR BG

.bg-color-blue,
.bg-color-blue--transparent,
.bg-color-green,
.bg-color-green--transparent,
.bg-color-red,
.bg-color-red--transparent,
.bg-color-charcoal,
.bg-color-charcoal--transparent {
  .lg-btn--primary-dark {
    // use Primary light styles for primary dark on dark backgrounds
    --btn-primary-dark-active-bg-color: var(
      --btn-primary-light-active-bg-color
    );
    --btn-primary-dark-bg-color: var(--btn-primary-light-bg-color);
    --btn-primary-dark-color: var(--btn-primary-light-color);
    --btn-primary-dark-border-color: var(--btn-primary-light-border-color);
    background-color: var(--btn-primary-light-bg-color);
    border: var(--btn-primary-light-border-color) solid var(--border-width);
    color: var(--btn-primary-light-color);

    &:not(:disabled):hover,
    &:focus-visible:not(.lg-btn-toggle),
    &:visited:not(:disabled):hover {
      --btn-primary-dark-hover-bg-color: rgba(var(--color-black-rgb), 0.2);
      --btn-primary-dark-hover-border-color: var(--color-charcoal) solid
        var(--border-width);
    }

    &:focus,
    &:visited {
      background-color: var(--btn-primary-light-bg-color);
      border: var(--btn-primary-light-border-color) solid var(--border-width);
      color: var(--btn-primary-light-color);
    }

    &:hover,
    &:focus:hover,
    &:visited:hover {
      border: var(--btn-primary-light-active-border-color) solid
        var(--border-width);
    }

    &:not(:disabled):active,
    &:not(:disabled):active:hover,
    &:focus:not(:disabled):active,
    &:focus:not(:disabled):active:hover,
    &:visited:not(:disabled):active,
    &:visited:not(:disabled):active:hover {
      background-color: var(--btn-primary-light-active-bg-color);
      border: var(--btn-primary-light-active-border-color) solid
        var(--border-width);
      color: var(--btn-primary-light-active-color);
    }

    &:not(:disabled):hover,
    &:focus:not(:disabled):hover,
    &:visited:not(:disabled):hover {
      background-color: var(--btn-primary-light-hover-bg-color);
      border: var(--btn-primary-light-hover-border-color) solid
        var(--border-width);
      color: var(--btn-primary-light-hover-color);
    }

    &:focus {
      border-color: transparent;
    }

    &:focus:not(:disabled):active,
    &:focus:not(:disabled):active:hover,
    &:focus:not(:disabled):hover {
      border-color: transparent;
    }
  }

  &:not(.bg-color-yellow) {
    .lg-btn--secondary-dark {
      // use Secondary light styles for secondary dark on dark backgrounds
      background-color: var(--btn-secondary-light-bg-color);
      border: var(--btn-secondary-light-border-color) solid var(--border-width);
      color: var(--btn-secondary-light-color);

      &:focus,
      &:visited {
        background-color: var(--btn-secondary-light-bg-color);
        border: var(--btn-secondary-light-border-color) solid
          var(--border-width);
        color: var(--btn-secondary-light-color);
      }

      &:hover,
      &:focus:hover,
      &:visited:hover {
        border: var(--btn-secondary-light-active-border-color) solid
          var(--border-width);
      }

      &:not(:disabled):active,
      &:not(:disabled):active:hover,
      &:focus:not(:disabled):active,
      &:focus:not(:disabled):active:hover,
      &:visited:not(:disabled):active,
      &:visited:not(:disabled):active:hover {
        background-color: var(--btn-secondary-light-active-bg-color);
        border: var(--btn-secondary-light-active-border-color) solid
          var(--border-width);
        color: var(--btn-secondary-light-active-color);
      }

      &:not(:disabled):hover,
      &:focus:not(:disabled):hover,
      &:visited:not(:disabled):hover {
        background-color: var(--btn-secondary-light-hover-bg-color);
        border: var(--btn-secondary-light-hover-border-color) solid
          var(--border-width);
        color: var(--btn-secondary-light-hover-color);
      }

      &:focus {
        border-color: transparent;
      }

      &:focus:not(:disabled):active,
      &:focus:not(:disabled):active:hover,
      &:focus:not(:disabled):hover {
        border-color: transparent;
      }
    }
  }
}

.bg-color-light-blue,
.bg-color-light-green,
.bg-color-yellow,
.bg-color-light-yellow,
.bg-color-light-red,
.bg-color-lightest-grey,
.bg-color-light-grey,
.bg-color-transparent,
.bg-color-white--transparent,
.bg-color-lightest-blue--transparent,
.bg-color-lightest-green--transparent,
.bg-color-yellow--transparent,
.bg-color-lightest-yellow--transparent,
.bg-color-lightest-red--transparent,
.bg-color-lightest-grey--transparent,
.bg-color-light-grey--transparent {
  .lg-btn--primary-light {
    // use Primary dark styles for primary light on light backgrounds
    background-color: var(--btn-primary-dark-bg-color);
    border: var(--btn-primary-dark-border-color) solid var(--border-width);
    color: var(--btn-primary-dark-color);

    &:focus,
    &:visited {
      background-color: var(--btn-primary-light-bg-color);
      border: var(--btn-primary-dark-border-color) solid var(--border-width);
      color: var(--btn-primary-dark-color);
    }

    &:hover,
    &:focus:hover,
    &:visited:hover {
      border: var(--btn-primary-dark-active-border-color) solid
        var(--border-width);
    }

    &:not(:disabled):active,
    &:not(:disabled):active:hover,
    &:focus:not(:disabled):active,
    &:focus:not(:disabled):active:hover,
    &:visited:not(:disabled):active,
    &:visited:not(:disabled):active:hover {
      background-color: var(--btn-primary-dark-active-bg-color);
      border: var(--btn-primary-dark-active-border-color) solid
        var(--border-width);
      color: var(--btn-primary-dark-active-color);
    }

    &:not(:disabled):hover,
    &:focus:not(:disabled):hover,
    &:visited:not(:disabled):hover {
      background-color: var(--btn-primary-dark-hover-bg-color);
      border: var(--btn-primary-dark-hover-border-color) solid
        var(--border-width);
      color: var(--btn-primary-dark-hover-color);
    }

    &:focus {
      border-color: transparent;
    }

    &:focus:not(:disabled):active,
    &:focus:not(:disabled):active:hover,
    &:focus:not(:disabled):hover {
      border-color: transparent;
    }
  }

  .lg-btn--secondary-light {
    // use Secondary dark styles for secondary light on light backgrounds
    background-color: var(--btn-secondary-dark-bg-color);
    border: var(--btn-secondary-dark-border-color) solid var(--border-width);
    color: var(--btn-secondary-dark-color);

    &:focus,
    &:visited {
      background-color: var(--btn-secondary-dark-bg-color);
      border: var(--btn-secondary-dark-border-color) solid var(--border-width);
      color: var(--btn-secondary-dark-color);
    }

    &:hover,
    &:focus:hover,
    &:visited:hover {
      border: var(--btn-secondary-dark-active-border-color) solid
        var(--border-width);
    }

    &:not(:disabled):active,
    &:not(:disabled):active:hover,
    &:focus:not(:disabled):active,
    &:focus:not(:disabled):active:hover,
    &:visited:not(:disabled):active,
    &:visited:not(:disabled):active:hover {
      background-color: var(--btn-secondary-dark-active-bg-color);
      border: var(--btn-secondary-dark-active-border-color) solid
        var(--border-width);
      color: var(--btn-secondary-dark-active-color);
    }

    &:not(:disabled):hover,
    &:focus:not(:disabled):hover,
    &:visited:not(:disabled):hover {
      background-color: var(--btn-secondary-dark-hover-bg-color);
      border: var(--btn-secondary-dark-hover-border-color) solid
        var(--border-width);
      color: var(--btn-secondary-dark-hover-color);
    }

    &:focus {
      border-color: transparent;
    }

    &:focus:not(:disabled):active,
    &:focus:not(:disabled):active:hover,
    &:focus:not(:disabled):hover {
      border-color: transparent;
    }
  }
}

.bg-color-blue,
.bg-color-green,
.bg-color-red,
.bg-color-charcoal,
.bg-color-charcoal--transparent,
.bg-color-blue--transparent,
.bg-color-red--transparent,
.bg-color-green--transparent {
  a {
    color: var(--color-white);
    border-color: var(--color-white);

    &:not(.lg-btn) {
      &:hover,
      &:active {
        box-shadow:
          inset 0 -0.063rem 0 0 var(--color-white),
          0 0.188rem 0 0 var(--color-white);
        background-color: rgba(var(--color-black-rgb), 0.2);
      }
    }

    &:hover:visited {
      color: var(--color-white);
    }
  }
}

.bg-color-yellow,
.bg-color-yellow--transparent {
  a {
    color: var(--color-charcoal);
    border-color: var(--color-charcoal);

    &:not(.lg-btn) {
      &:hover,
      &:active {
        box-shadow:
          inset 0 -0.063rem 0 0 var(--color-charcoal),
          0 0.188rem 0 0 var(--color-charcoal);
        background-color: rgba(var(--color-black-rgb), 0.48);
      }
    }

    &:hover:visited {
      color: var(--color-charcoal);
    }
  }

  .lg-btn--primary-dark {
    &:focus-visible:not(.lg-btn-toggle),
    &:not(:disabled):hover,
    &:visited:not(:disabled):hover {
      --btn-primary-dark-hover-bg-color: rgba(var(--color-black-rgb), 0.2);
      --btn-primary-dark-hover-border-color: var(--color-charcoal) solid
        var(--border-width);
    }

    &:not(:disabled) {
      background-color: var(--color-charcoal);
      border: var(--color-charcoal) solid var(--border-width);
      color: var(--color-white);

      &:visited {
        background-color: var(--color-charcoal);
        border: var(--color-charcoal) solid var(--border-width);
        color: var(--color-white);
      }

      &:hover,
      &:visited:hover {
        background-color: var(--color-charcoal-dark);
        border: var(--color-charcoal-dark) solid var(--border-width);
      }

      &:active,
      &:visited:active,
      &:active:hover {
        --btn-primary-dark-active-bg-color: var(--color-charcoal-dark);
        border: var(--btn-primary-light-border-color) solid var(--border-width);
        color: var(--btn-primary-light-color);
      }

      &:focus,
      &:focus-visible {
        background-color: var(--btn-primary-light-bg-color);
        border: var(--btn-primary-light-border-color) solid var(--border-width);
        color: var(--btn-primary-light-color);
        border-color: transparent;
        box-shadow:
          0 0 0 0.125rem var(--color-charcoal),
          0 0 0 0.375rem var(--color-white);
      }

      &:focus:not(:disabled):active {
        border-color: transparent;
        background-color: var(--color-black);
      }
    }
  }

  .lg-btn--secondary-dark {
    &:not(:disabled):hover,
    &:not(:disabled):visited:hover,
    &:not(:focus):hover,
    &:not(:visited):focus:hover {
      background-color: rgba(var(--color-black-rgb), 0.48);
      border: rgba(var(--color-black-rgb), 0.48) solid var(--border-width);
      color: var(--color-white);
    }

    &:not(:disabled):focus,
    &:not(:disabled):visited:focus,
    &:not(:disabled):visited:focus:hover {
      background-color: var(--color-white);
      border: rgba(var(--color-black-rgb), 0.48) solid var(--border-width);
      color: var(--color-charcoal);
      box-shadow:
        0 0 0 0.125rem var(--color-charcoal),
        0 0 0 0.375rem var(--color-white);
    }

    &:not(:disabled):active,
    &:not(:disabled):active:hover,
    &:not(:disabled):visited:active,
    &:not(:disabled):visited:active:hover {
      background-color: rgba(var(--color-black-rgb), 0.66);
      border-color: transparent;
      color: var(--color-white);
    }
  }
}

// LAYOUT
.row-reverse {
  flex-direction: row-reverse;
}

.lg-container .lg-container {
  padding: 0;
}

.block-spacing {
  padding-bottom: var(--space-lg);

  &:not(.followup-cta-button) {
    display: block;
  }

  &.followup-cta-button {
    display: block;

    @include mixins.lg-breakpoint(md) {
      display: inline-block;
    }
  }

  @include mixins.lg-breakpoint(md) {
    padding-bottom: var(--space-xxl);
  }

  @include mixins.lg-breakpoint(lg) {
    padding-bottom: var(--space-xxxl);
  }

  &--double {
    padding-bottom: calc(var(--space-lg) * 2);

    @include mixins.lg-breakpoint(md) {
      padding-bottom: calc(var(--space-xxl) * 2);
    }

    @include mixins.lg-breakpoint(lg) {
      padding-bottom: var(--space-xxxxl);
    }
  }

  &--half {
    padding-bottom: calc(var(--space-lg) / 2);

    @include mixins.lg-breakpoint(md) {
      padding-bottom: calc(var(--space-xxl) / 2);
    }

    @include mixins.lg-breakpoint(lg) {
      padding-bottom: calc(var(--space-xxxl) / 2);
    }
  }

  &--zero {
    padding-bottom: 0;
  }
}

.scroll-lock {
  overflow: hidden;
}

.main-content {
  min-height: calc(100vh - var(--header-height));
  box-shadow: inset 0 4px 0 0 rgba(0, 0, 0, 0.03);
}

.aspect-ratio {
  position: relative;
  overflow: hidden;

  &__16-9 {
    padding-top: 56.25%;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.text-color-blue {
  color: var(--color-super-blue);

  &.highlight-content-bar__circular-infographic--chart-text {
    fill: var(--color-super-blue);
  }
}

.text-color-green {
  color: var(--color-leafy-green);

  &.highlight-content-bar__circular-infographic--chart-text {
    fill: var(--color-leafy-green);
  }
}

.text-color-red {
  color: var(--color-poppy-red);

  &.highlight-content-bar__circular-infographic--chart-text {
    fill: var(--color-poppy-red);
  }
}

.background-panel {
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}

.page-title {
  @include mixins.lg-breakpoint(md, "max-width") {
    --text-fs-7-size: var(--text-fs-6-size);
    --text-fs-7-line-height: var(--text-fs-6-line-height);
  }

  @include mixins.lg-breakpoint(lg) {
    font-size: var(--text-fs-7-size);
    line-height: var(--text-fs-7-line-height);
  }

  padding-top: calc(1.25 * var(--space-unit));
  font-weight: var(--font-weight-medium);

  @include mixins.lg-breakpoint(md) {
    padding-top: var(--space-md);
  }

  @include mixins.lg-breakpoint(lg) {
    padding-top: var(--space-lg);
  }

  &.lg-col-md-8,
  &.lg-col-lg-8 {
    padding-left: 0;
    padding-right: 0;
  }
}

.bg-color-charcoal,
.bg-color-blue,
.bg-color-red,
.bg-color-green,
.bg-color-charcoal--transparent,
.bg-color-blue--transparent,
.bg-color-red--transparent,
.bg-color-green--transparent {
  --link-menu-link-color: var(--color-white);
  --link-menu-link-hover-color: var(--color-white);
  --link-menu-link-active-color: var(--color-white);

  .link-list a:not(.lg-btn):hover {
    box-shadow: none;
  }
}

.bg-color-yellow,
.bg-color-yellow--transparent {
  --link-menu-link-color: var(--color-charcoal);
  --link-menu-link-hover-color: var(--color-white);
  --link-menu-link-active-color: var(--color-white);
  --link-menu-link-border-color: var(--color-charcoal);
  --default-focus-color: var(--color-white);

  .link-list a:not(.lg-btn) {
    &:focus {
      box-shadow:
        0 0 0 0.125rem var(--color-charcoal),
        0 0 0 0.375rem var(--color-white);
      background-color: var(--color-white);
      color: var(--color-charcoal);
      border-bottom: none;
      margin-bottom: 0;
    }

    &:hover {
      color: var(--color-white);
      box-shadow: none;
      border-bottom-color: var(--color-white);
    }

    &:focus:hover {
      box-shadow:
        0 0 0 0.125rem var(--color-charcoal),
        0 0 0 0.375rem var(--color-white);
      background-color: rgba(var(--color-black-rgb), 0.48);
      color: var(--color-charcoal);
      border-bottom: none;
      margin-bottom: 0;
    }

    &:active {
      box-shadow: none;
      background-color: rgba(var(--color-black-rgb), 0.66);
      border-bottom-color: var(--color-white);
    }
  }
}

.bg-color-charcoal {
  .link-list a:not(.lg-btn):focus:hover {
    box-shadow: 0 0 0 0.25rem var(--default-focus-color);
    background-color: rgba(var(--color-black-rgb), 0.2);
  }
}

.followup-cta-button {
    dotcom-frontend-button {
      .text-block & {
        margin-top: var(--space-lg);
        margin-right: 0;
        @include mixins.lg-breakpoint(md) {
          margin-top: calc(var(--space-lg) + var(--space-xxxs));
        }
        @include mixins.lg-breakpoint(lg) {
          margin-top: var(--space-xl);
        }
      }
      margin-top: 0;
    }

  &:not(:last-child) {
    .lg-btn {
      margin-right: var(--space-sm);
    }

    @include mixins.lg-breakpoint(md, "max-width") {
      .lg-btn {
        margin-bottom: var(--space-md);
      }
    }
  }

  .lg-btn {
    margin-bottom: 0;
  }
}

// BLOCKS WITH BG

.bg-color-transparent,
.bg-color-white {
  .text-block__container,
  .highlight-content-bar__container {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.bg-color-blue,
.bg-color-green,
.bg-color-red,
.bg-color-charcoal,
.bg-color-yellow,
.bg-color-light-blue,
.bg-color-light-yellow,
.bg-color-light-green,
.bg-color-light-red,
.bg-color-light-grey,
.bg-color-lightest-grey {
  .text-block__container,
  .highlight-content-bar__container {
    padding-bottom: var(--space-lg);
    padding-top: var(--space-lg);

    @include mixins.lg-breakpoint(md) {
      padding-bottom: var(--space-xxl);
      padding-top: var(--space-xxl);
    }

    @include mixins.lg-breakpoint(lg) {
      padding-bottom: var(--space-xxxl);
      padding-top: var(--space-xxxl);
    }
  }

  .grid-block__container {
    padding-top: calc(var(--space-lg) + 4px);
    padding-bottom: 0;

    &-spacing {
      padding-bottom: calc(var(--space-lg) + 4px);
    }
  }
}

// WYSIWYG CMS HTML

.wysiwyg {
  h2 {
    font-size: var(--text-fs-4-size);
    font-weight: var(--font-weight-regular);
    line-height: var(--text-fs-4-line-height);
  }

  h3 {
    font-size: var(--text-fs-3-size);
    font-weight: var(--font-weight-regular);
    line-height: var(--text-fs-1-line-height);
  }

  h4 {
    font-size: var(--text-fs-2-size);
    font-weight: var(--font-weight-regular);
    line-height: var(--text-fs-4-line-height);
  }

  sup {
    vertical-align: super;
  }

  sub {
    vertical-align: sub;
  }

  ul {
    padding-left: 0;
    margin-left: var(--space-unit);
  }
  a {
    overflow-wrap: break-word;
  }

  &-icon {
    list-style-type: none;
    padding-left: var(--space-xl);
    background-position: left top;
    background-size: calc(var(--space-md) + 2px) var(--space-lg);
    background-repeat: no-repeat;
    line-height: var(--space-lg);
    margin-left: -20px;

    .highlightCell &,
    .bg-color-blue &,
    .bg-color-green &,
    .bg-color-red &,
    .bg-color-charcoal & {
      filter: brightness(10);
    }

    &__accidental-damage {
      background-image: url("./assets/icons/accidental-damage.svg");
    }

    &__balance {
      background-image: url("./assets/icons/balance.svg");
    }

    &__bolt {
      background-image: url("./assets/icons/bolt.svg");
    }

    &__call {
      background-image: url("./assets/icons/call.svg");
    }

    &__care {
      background-image: url("./assets/icons/care.svg");
    }

    &__cat {
      background-image: url("./assets/icons/cat.svg");
    }

    &__chart-bar {
      background-image: url("./assets/icons/chart-bar.svg");
    }

    &__chat-group {
      background-image: url("./assets/icons/chat-group.svg");
    }

    &__checkmark-spot-filled {
      background-image: url("./assets/icons/checkmark-spot-filled.svg");
    }

    &__checkmark-spot-outline {
      background-image: url("./assets/icons/checkmark-spot-outline.svg");
    }

    &__checkmark {
      background-image: url("./assets/icons/checkmark.svg");
    }

    &__checkmark-green {
      background-image: url("./assets/icons/checkmark-green.svg");
    }

    &__chevron-down-circle {
      background-image: url("./assets/icons/chevron-down-circle.svg");
    }

    &__chevron-up-circle {
      background-image: url("./assets/icons/chevron-up-circle.svg");
    }

    &__circle {
      background-image: url("./assets/icons/circle.svg");
    }

    &__cracked-mirror {
      background-image: url("./assets/icons/cracked-mirror.svg");
    }

    &__cross {
      background-image: url("./assets/icons/cross.svg");
    }

    &__cross-red {
      background-image: url("./assets/icons/cross-red.svg");
    }

    &__dash {
      background-image: url("./assets/icons/dash.svg");
    }

    &__droplets {
      background-image: url("./assets/icons/droplets.svg");
    }

    &__family {
      background-image: url("./assets/icons/family.svg");
    }

    &__favourite-filled {
      background-image: url("./assets/icons/favourite-filled.svg");
    }

    &__favourite-outline {
      background-image: url("./assets/icons/favourite-outline.svg");
    }

    &__feature {
      background-image: url("./assets/icons/feature.svg");
    }

    &__fire {
      background-image: url("./assets/icons/fire.svg");
    }

    &__focus {
      background-image: url("./assets/icons/focus.svg");
    }

    &__funds {
      background-image: url("./assets/icons/funds.svg");
    }

    &__furniture {
      background-image: url("./assets/icons/furniture.svg");
    }

    &__house {
      background-image: url("./assets/icons/house.svg");
    }

    &__lump-sum {
      background-image: url("./assets/icons/lump-sum.svg");
    }

    &__pet {
      background-image: url("./assets/icons/pet.svg");
    }

    &__running-tap {
      background-image: url("./assets/icons/running-tap.svg");
    }

    &__snowflake {
      background-image: url("./assets/icons/snowflake.svg");
    }

    &__square-outline {
      background-image: url("./assets/icons/square-outline.svg");
    }

    &__square {
      background-image: url("./assets/icons/square.svg");
    }

    &__step-1 {
      background-image: url("./assets/icons/step-1.svg");
    }

    &__step-2 {
      background-image: url("./assets/icons/step-2.svg");
    }

    &__step-3 {
      background-image: url("./assets/icons/step-3.svg");
    }

    &__storm {
      background-image: url("./assets/icons/storm.svg");
    }

    &__television {
      background-image: url("./assets/icons/television.svg");
    }

    &__water {
      background-image: url("./assets/icons/water.svg");
    }
  }
}

.hero-block__subtitle,
.heading__subtitle {
  &.wysiwyg {
    sup,
    sub {
      font-size: var(--text-fs-3-size);
      font-weight: var(--font-weight-regular);
      line-height: var(--text-fs-1-line-height);
    }
  }
}

// SIMPLE TABLES
.wysiwyg-table {
  border-collapse: collapse;
  font-size: var(--text-base-size);

  tbody tr {
    &:nth-child(odd) {
      background-color: var(--table-stripe-color);
    }

    &:hover {
      background-color: var(--color-platinum);
    }
  }

  thead tr {
    background-color: transparent;
  }

  th,
  td {
    border: 0;
    padding: var(--space-sm);
  }

  th {
    text-align: left;
    border-bottom: solid var(--table-header-border-width)
      var(--table-header-border-color);
  }
}

.box-shadow {
  box-shadow:
    0 8px 16px 0 rgba(var(--color-black-rgb), 0.04),
    0 3px 7px 0 rgba(var(--color-black-rgb), 0.03),
    0 2px 4px 0 rgba(var(--color-black-rgb), 0.02),
    0 1px 2px 0 rgba(var(--color-black-rgb), 0.02),
    0 1px 1px 0 rgba(var(--color-black-rgb), 0.02),
    0 0 0 0 rgba(var(--color-black-rgb), 0.01);

  &.has-hover-shadow {
    transition: box-shadow 0.3s ease-in-out;

    &:hover,
    &:visited,
    &:hover:visited,
    > a:hover {
      box-shadow:
        0 32px 80px rgba(var(--color-black-rgb), 0.07),
        0 13px 33px rgba(var(--color-black-rgb), 0.05),
        0 7px 18px rgba(var(--color-black-rgb), 0.04),
        0 4px 10px rgba(var(--color-black-rgb), 0.04),
        0 2px 5px rgba(var(--color-black-rgb), 0.03),
        0 1px 2px rgba(var(--color-black-rgb), 0.02);
    }
  }
}

.no-border {
  border: none !important;
}

.no-shadow {
  box-shadow: none !important;
}

ol {
  margin-left: var(--space-md);
}
